export enum FeatureName {
  ZingStudioActive = 'Zing.Studio.Active',
  ZingMemoActive = 'Zing.Memo.Active',
  ZingShippingForbiddenWordsOnlyLetters = 'Zing.Shipping.ForbiddenWords.OnlyLetters',
  ZingUploadActive = 'Zing.Studio.UploadActive',
  ZingShipStationActive = 'Zing.Shipping.ShipStationActive',
  ZingStudioFlatShipmentRateActive = 'Zing.Studio.FlatShipmentRateActive',
  ZingMarketplaceNivodaEnabled = 'Zing.Marketplace.NivodaEnabled',
  ZingMarketplaceReturnDiamondsEnabled = 'Zing.Marketplace.ReturnDiamondsEnabled',
  ZingStudioPromoCodeActive = 'Zing.Studio.PromoCodeActive',
  ZingShippingEtsyActive = 'Zing.Shipping.EtsyActive',
  ZingShippingFedExActive = 'Zing.Shipping.FedExActive',
  ZingShippingPickupWarningActive = 'Zing.Shipping.PickupWarningActive',
  ZingShippingWooCommerceActive = 'Zing.Shipping.WooCommerceActive',
  ZingPointOfSaleIntegrationJmccIsRegistrationEnabled = 'Zing.PointOfSaleIntegration.Jmcc.IsRegistrationEnabled',
  ZingMembershipNonJewelerRegistrationActive = 'Zing.Membership.NonJewelerRegistrationActive'
}
