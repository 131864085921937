import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Angulartics2Module } from 'angulartics2';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AutoLoginComponent } from './modules/core/auto-login/auto-login.component';
import { CallbackComponent } from './modules/core/callback.component';
import { ParticipantAgreementComponent } from './modules/core/participant-agreement/participant-agreement.component';
import { SelectivePreloadStrategyService } from './modules/core/services/selective-preload-strategy.service';
import { TermsOfUseComponent } from './modules/core/terms-of-use/terms-of-use.component';
import { UserRoleCode } from './modules/membership/modules/account/models/user-role-code.enum';
import { ParnerGatewayEnabledGuard } from './modules/membership/modules/dashboard/route-guards/partner-gateway-enabled.guard';
import { FaqComponent } from './modules/membership/modules/shipping/components/faq/faq.component';
import { BusinessServiceType } from './modules/shared/enums/business-service-type';
import { FeatureName } from './modules/shared/enums/feature-name';
import { ApplicationBrowserGuard } from './route-guards/application-browser-guard.service';
import { GuestGuard } from './route-guards/guest-guard.service';
import { MemberCanGuard } from './route-guards/member-can-guard.service';
import { MemberGuard } from './route-guards/member-guard.service';
import { PermissionGuard } from './route-guards/permission.guard';
import { ProfilesPreloadGuard } from './route-guards/profiles-preload.guard';
import { RoleGuard } from './route-guards/role-guard.service';
import { SubscriptionGuard } from './route-guards/subscription-guard.service';

const ROOT_ROUTES: Routes = [
  {
    path: '',
    canActivate: [GuestGuard],
    loadChildren: () => import('./modules/guest/guest.module').then(m => m.GuestModule)
  },
  {
    path: 'membership',
    canActivate: [MemberGuard, ProfilesPreloadGuard],
    canLoad: [MemberCanGuard],
    loadChildren: () => import('./modules/membership/membership.module').then(m => m.MembershipModule),
  },
  {
    path: 'dashboard',
    canActivate: [MemberGuard, ProfilesPreloadGuard, ParnerGatewayEnabledGuard],
    canLoad: [MemberCanGuard],
    data: {
      nivodaFlag: FeatureName.ZingMarketplaceNivodaEnabled
    },
    loadChildren: () => import('./modules/membership/modules/dashboard/dashboard.module')
      .then(m => m.DashboardModule),
  },
  {
    path: 'policy/business',
    canActivate: [MemberGuard, ProfilesPreloadGuard],
    canLoad: [MemberCanGuard],
    loadChildren: () => import('./modules/membership/modules/commercial-lines/commercial-lines.module').then(m => m.CommercialLinesModule),
  },
  {
    path: 'appraisals',
    canActivate: [MemberGuard, ProfilesPreloadGuard, SubscriptionGuard, PermissionGuard],
    data: {
      roles: [],
      requiredSubscriptions: [BusinessServiceType.Appraisal],
      requiredPermission: BusinessServiceType.Appraisal
    },
    loadChildren: () => import('./modules/appraisal/appraisal.module').then(m => m.AppraisalModule),
    canLoad: [MemberCanGuard]
  },
  {
    path: 'jmcc',
    canActivate: [MemberGuard, ProfilesPreloadGuard, SubscriptionGuard, RoleGuard],
    data: {
      roles: [
        UserRoleCode.BusinessAdministrator,
        UserRoleCode.JMPointOfSaleAdministrator,
        UserRoleCode.JMCustomerService,
        UserRoleCode.BusinessLocationAdministrator,
        UserRoleCode.BusinessUser],
      requiredSubscriptions: [BusinessServiceType.PointOfSaleIntegration]
    },
    loadChildren: () => import('./modules/jmcc-dashboard/jmcc-dashboard.module').then(m => m.JmccDashboardModule),
    canLoad: [MemberCanGuard]
  },
  {
    path: 'memo',
    canActivate: [MemberGuard, ProfilesPreloadGuard, SubscriptionGuard],
    canLoad: [MemberCanGuard],
    data: {
      requiredSubscriptions: [BusinessServiceType.Memo]
    },
    loadChildren: () => import('./modules/memo/memo.module').then(m => m.MemoModule),
  },
  {
    path: 'jewelerpages',
    canActivate: [MemberGuard, ProfilesPreloadGuard, SubscriptionGuard, PermissionGuard],
    canLoad: [MemberCanGuard],
    data: {
      requiredSubscriptions: [BusinessServiceType.JewelerPages],
      requiredPermission: BusinessServiceType.JewelerPages
    },
    loadChildren: () => import('./modules/jeweler-pages/jeweler-pages.module').then(jp => jp.JewelerPagesModule),
  },
  {
    path: 'marketplace',
    canLoad: [MemberCanGuard],
    canActivate: [MemberGuard, ProfilesPreloadGuard, SubscriptionGuard, PermissionGuard],
    data: {
      requiredSubscriptions: [BusinessServiceType.MarketPlace],
      requiredPermission: BusinessServiceType.MarketPlace
    },
    loadChildren: () => import('./modules/marketplace/marketplace.module').then(m => m.MarketplaceModule),
  },
  {
    path: 'studio',
    canLoad: [MemberCanGuard],
    canActivate: [MemberGuard, ProfilesPreloadGuard, SubscriptionGuard],
    data: {
      requiredSubscriptions: [BusinessServiceType.Studio]
    },
    loadChildren: () => import('./modules/studio/studio.module').then(m => m.StudioModule),
  },
  {
    path: 'jmconnect',
    canActivate: [ApplicationBrowserGuard, GuestGuard],
    loadChildren: () => import('./modules/jm-connect/jm-connect.module').then(m => m.JmConnectModule),
  },
  {
    path: 'callback',
    component: CallbackComponent,
    canActivate: [ApplicationBrowserGuard],
    data: {
      title: 'Call Back'
    }
  },
  {
    path: 'login',
    component: AutoLoginComponent,
    canActivate: [ApplicationBrowserGuard],
    data: {
      title: 'Login'
    }
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    canActivate: [ApplicationBrowserGuard],
    data: {
      title: 'Forbidden'
    }
  },
  {
    path: 'legal/participant-agreement',
    component: ParticipantAgreementComponent,
    canActivate: [ApplicationBrowserGuard],
    data: {
      title: 'Participant Agreement'
    }
  },
  {
    path: 'legal/terms-of-use',
    component: TermsOfUseComponent,
    canActivate: [ApplicationBrowserGuard],
    data: {
      title: 'Terms of Use'
    }
  },
  {
    path: 'shippingfaq',
    component: FaqComponent,
    canActivate: [ApplicationBrowserGuard],
    data: {
      title: 'Shipping FAQ'
    }
  },
  {
    path: 'external',
    canActivate: [ApplicationBrowserGuard],
    loadChildren: () => import('./modules/external/external.module').then(m => m.ExternalModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      title: 'Not Found'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(ROOT_ROUTES, {
    preloadingStrategy: SelectivePreloadStrategyService,
    initialNavigation: 'enabledNonBlocking'
  }),
  Angulartics2Module.forRoot({
    pageTracking: {
      clearIds: true,
      clearQueryParams: true,
      clearHash: true,
      excludedRoutes: [
        new RegExp('loading'),
        new RegExp('callback')
      ]
    }
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
