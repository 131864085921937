import { CartInformation } from '../../shopping-cart/models/cart-information';
import { ModuleType } from '../../shopping-cart/models/module-type';

export class NaturalShoppingCart {
  id: string;
  userReferenceId: string;
  personId: string;
  retailIndicator: number;
  items: NaturalCartItem[];
  count: number;
  cartInformation: CartInformation[];
  moduleType: ModuleType = ModuleType.NaturalDiamonds;
  reasonToDelete: string;
}

export class NaturalCartItem  {
  type: number;
  shape: string;
  carat: number;
  cutGrade: string;
  cutGradeDescription: string; // TODO: Boris - CHECK THIS
  sourceCountryCode: string;
  sourceRegionName: string;
  clarity: string;
  color: string;
  provenanceReportName: string;
  provenanceReportUrl: string;
  gradingLab: string;
  gradingReportNumber: string;
  supplierUploadedReportUrl: string;
  reportUrl: string;
  supplierUploadedDiamondImageUrl: string;
  supplierUploadedOnlineReportUrl: string;
  idexDiamondImage: string;
  lightPerformance: string;
  proportionFactor: string;
  reportType: string;
  supplierVideoUrl: string;
  supplierAgsLightPerformanceUrl: string;
  supplierAssetImageUrl: string;
  supplierHeartsImageUrl: string;
  supplierArrowsImageUrl: string;
  pricePerCarat: number;
  offIdexList: string;
  program: string;
  supplierItemIdentifier: string;
  isPriceUpdated: boolean;
  id: string;
  availableIndicator: boolean;
  price: number;
  displayImageUrl: string;
}

export const emptyCart: NaturalShoppingCart = {
  id: null,
  userReferenceId: '',
  personId: '',
  retailIndicator: 0,
  items: [],
  count: 0,
  cartInformation: [],
  moduleType: ModuleType.NaturalDiamonds,
  reasonToDelete: '',
};
