import { Address } from './address.model';
import { PersonCompany } from './person-company.model';
import { ServiceSubscription } from '../../../../shared/models/service-subscription';
import { BusinessServiceType } from '../../../../shared/enums/business-service-type';
import { ApprovalStatus } from '../enums/approval-status';
import { AddressValidation } from '../../../../shared/enums/address-validation';
import { CarePlanStoreType } from '../../../../shared/enums/care-plan-store-type';
import { RegistrationSource } from './person-source.enum';
import { MarketplacePartnerAffiliationType } from '../../../../shared/enums/marketplace-partner-affiliation-types';
import { MarketplacePartnerAffiliation } from '../../../../shared/models/marketplace-partner-affiliation';
import { BusinessClassification } from './business-classification';

export class Company {
  name: string;
  websiteUrl: string;
  phone: string;
  numberOfEmployees: number;
  establishmentDate: Date;
  closureDate: Date;
  federalTaxIdentificationNumber: string;
  isActive: boolean;
  companyGroup: string;
  companyRoles: string[];
  notes: string;
  collabGroup: string;
  currentGroupID: number;
  userName: string;
  userId: number;
  address: Address;
  linkPersonCompany: PersonCompany;
  id: string;
  subscriptions: Array<ServiceSubscription>;
  hasSubscriptions: boolean;
  addressValidation?: AddressValidation;
  companySource: RegistrationSource;
  analyticsDeactivated?: boolean;
  classification?: BusinessClassification;
  constructor(data: Company) {
    this.name = data.name;
    this.websiteUrl = data.websiteUrl;
    this.phone = data.phone;
    this.numberOfEmployees = data.numberOfEmployees;
    this.establishmentDate = data.establishmentDate;
    this.closureDate = data.closureDate;
    this.federalTaxIdentificationNumber = data.federalTaxIdentificationNumber;
    this.isActive = data.isActive;
    this.companyGroup = data.companyGroup;
    this.companyRoles = data.companyRoles;
    this.notes = data.notes;
    this.collabGroup = data.collabGroup;
    this.currentGroupID = data.currentGroupID;
    this.userName = data.userName;
    this.userId = data.userId;
    this.address = data.address;
    this.linkPersonCompany = data.linkPersonCompany;
    this.id = data.id;
    this.subscriptions = data.subscriptions;
    this.hasSubscriptions = data.hasSubscriptions;
    this.companySource = data.companySource;
    this.analyticsDeactivated = data.analyticsDeactivated;
    this.classification = data.classification;
  }

  get companyId(): string {
    return this.id;
  }

  isSubscriptionActive(serviceType: BusinessServiceType): boolean {
    if (this.hasSubscriptions) {
      const subIndex = this.subscriptions.findIndex(s => s.isActive && s.serviceType === serviceType
        && s.approvalStatus === ApprovalStatus.Approved);
      return subIndex > -1;
    }
    return false;
  }

  isSubscriptionInactive(serviceType: BusinessServiceType): boolean {
    if (this.hasSubscriptions) {
      const subIndex = this.subscriptions.findIndex(s => !s.isActive && s.serviceType === serviceType
        && (s.approvalStatus === ApprovalStatus.Approved || s.approvalStatus === ApprovalStatus.Rejected));
      return subIndex > -1;
    }
    return false;
  }

  isSubscriptionPending(serviceType: BusinessServiceType): boolean {
    if (this.hasSubscriptions) {
      const subIndex = this.subscriptions.findIndex(s => !s.isActive && s.serviceType === serviceType
        && (s.approvalStatus === ApprovalStatus.None || s.approvalStatus === ApprovalStatus.Invited));
      return subIndex > -1;
    }
    return false;
  }

  hasServiceSubscription(serviceType: BusinessServiceType): boolean {
    if (this.hasSubscriptions) {
      const subIndex = this.subscriptions.findIndex(s => s.serviceType === serviceType);
      return subIndex > -1;
    }
    return false;
  }

  isCarePlanJeweler(): boolean {
    if (this.hasSubscriptions) {
      const subIndex = this.subscriptions.findIndex(s => s.isActive && s.serviceType === BusinessServiceType.CarePlan
      && s.approvalStatus === ApprovalStatus.Approved);
      if (subIndex > - 1) {
        if (this.subscriptions[subIndex].storeTypes && this.subscriptions[subIndex].storeTypes.length > 0) {
          return this.subscriptions[subIndex].storeTypes.findIndex(s => s === CarePlanStoreType.Jeweler) > -1;
        }
      }
    }
    return false;
  }
  isCarePlanRepairCenter(): boolean {
    if (this.hasSubscriptions) {
      const subIndex = this.subscriptions.findIndex(s => s.isActive && s.serviceType === BusinessServiceType.CarePlan
      && s.approvalStatus === ApprovalStatus.Approved);
      if (subIndex > - 1) {
        if (this.subscriptions[subIndex].storeTypes && this.subscriptions[subIndex].storeTypes.length > 0) {
          return this.subscriptions[subIndex].storeTypes.findIndex(s => s === CarePlanStoreType.RepairCenter) > -1;
        }
      }
    }
    return false;
  }
  getSubscription(serviceType: BusinessServiceType): ServiceSubscription {
    let result: ServiceSubscription = null;
    if (this.hasSubscriptions) {
      result = this.subscriptions.find(s => s.serviceType === serviceType);
    }
    return result;
  }

  getPartnerAffiliation(affiliationType: MarketplacePartnerAffiliationType): MarketplacePartnerAffiliation {
    if (this.isSubscriptionActive(BusinessServiceType.MarketPlace)) {
      const marketplaceSubscription = this.subscriptions.find(s => s.serviceType === BusinessServiceType.MarketPlace);
      if (marketplaceSubscription.partnerAffiliations && marketplaceSubscription.partnerAffiliations.length) {
        return marketplaceSubscription.partnerAffiliations.find(pa => pa.affiliationType === affiliationType);
      }
    }

    return undefined;
  }
}
