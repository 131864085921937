import { PaginatedResponse } from '../../shared/models/paginated-response.model';
import { GridDataResult } from '@progress/kendo-angular-grid';
import * as moment from 'moment';
import { Person } from '../../membership/modules/account/models/person.model';
import {
  Diamond,
  DiamondDetails,
  OrderDetails,
  Orders,
  PriceChangeDetails,
  PriceField,
  ProductDetails
} from '../models';
import { Company } from '../../membership/modules/account/models/company.model';
import { CompanyLocation } from '../../membership/modules/account/models/location.model';
import { formatDateWithTimeZone } from '../../../utils/moment-date-time';
import { isLocationRestricted } from '../shared/utils';
import { OrderLocation } from '../../order/models/order-location';
import { DiamondType } from '../../shared/enums/diamond-type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NaturalCartItem } from '../models/natural-cart';

export function toGridDataResult(paginatedResponse: PaginatedResponse<any>): GridDataResult {
  return {
    data: paginatedResponse.items,
    total: paginatedResponse.totalRecordCount
  };
}

export function getActiveStatus({ customerOrderStatus }: OrderDetails) {
  if (customerOrderStatus && customerOrderStatus.length) {
    return customerOrderStatus[customerOrderStatus.length - 1];
  } else {
    return null;
  }
}

export function getOrderLocations(person: Person, company: Company,
  companyLocations: CompanyLocation[]): OrderLocation[] {
  return companyLocations.map(l => getOrderLocation(person, company, l));
}

export function getOrderLocation(person: Person, company: Company,
  companyLocation: CompanyLocation): OrderLocation {
  return {
    address: companyLocation.address,
    companyName: company.name,
    email: person.email,
    contact: `${person.firstName} ${person.lastName}`,
    locationName: companyLocation.locationName,
    phone1: companyLocation.phoneNumber,
    phone2: person.isCellPhoneVerified && person.cellPhone
      ? person.cellPhone
      : null,
    locationID: companyLocation.locationID,
    isRestricted: isLocationRestricted(companyLocation)
  };
}

export function toDiamondInfo(item: ProductDetails, isAgsUser = false): Partial<DiamondDetails> {
  return {
    diamondID: item.itemId,
    regionName: null,
    countryName: item.location,
    shape: item.shape,
    weight: item.carat,
    colorDescription: item.color,
    clarity: item.clarity,
    cutGradeDescription: getCutGrade(item, isAgsUser),
    cutGrade: item.cutGrade,
    lab: item.gradingLab,
    gradingReportNumber: item.gradingReportNumber,
    reportUrl: item.gradingReportUrl,
    idexDiamondImageUrl: item.idexDiamondImage,
    provenanceReport: item.provenanceReportName,
    supplierUploadedOnlineReportUrl: item.supplierUploadedOnlineReportURL,
    supplierUploadedDiamondImageUrl: item.supplierUploadedDiamondImageURL,
    supplierVideoUrl: item.supplierVideoUrl,
    supplierAsetImageUrl: item.supplierAsetImageUrl,
    supplierHeartsImageUrl: item.supplierHeartsImageUrl,
    supplierArrowsImageUrl: item.supplierArrowsImageUrl,
    supplierAgsLightPerformanceUrl: item.supplierAgsLightPerformanceUrl,
    provenanceReportUrl: item.provenanceReportURL,
    lightPerformance: item.lightPerformance,
    proportionFactor: item.proportionFactor,
    reportType: item.reportType,
    programs: [item.program],
    displayImageUrl: item.displayImageUrl
  };
}

export function isAGSDiamond(diamond: Partial<DiamondDetails> | Diamond): boolean {
  return diamond?.programs?.some(value => value?.toLowerCase() === 'ags');
}

export function isShoppingCartAGSDiamond(diamond: NaturalCartItem): boolean {
  return diamond?.program?.toLowerCase() === 'ags';
}

function getCutGrade(item: ProductDetails, isAgsUser = false) {
  if (isAgsUser && item.cutGrade) {
    return `${item.cutGradeDescription} (${item.cutGradeScale})`;
  } else {
    return item.cutGrade;
  }
}

export function getOdataDateFormat(date: string, endOfDay = false) {
  let momentDate = moment(date).utc(true);

  if (endOfDay) {
    momentDate = momentDate.endOf('day');
  }

  return momentDate.toDate()
    .toISOString()
    .split('.')
    .shift() + 'Z';
}

export function getPriceUpdateDetails(order: Orders) {
  if (order.pricingChange) {
    return mapPricingChangeDetails(order.pricingChange);
  } else {
    return null;
  }
}

export function getTotalTransactionPriceChange(pricingChanges: PriceChangeDetails[]) {
  return pricingChanges?.find(i => i.priceField === PriceField.Total_Transaction_Cost);
}

export function mapPricingChangeDetails(changeDetails: PriceChangeDetails, showReason = true) {
  return {
    date: formatDateWithTimeZone(changeDetails.dateOfUpdate, 'MM/DD/YYYY'),
    reason: changeDetails.changeReason && showReason
      ? ` - ${changeDetails.changeReason}.`
      : ''
  };
}

export function filterCriteriaByType(collection: any[], diamondTypeIndicator: DiamondType) {
  return collection?.filter(i => i.diamondTypeIndicator == diamondTypeIndicator);
}

export function filterLabGrownRecords<T extends { diamondTypeIndicator: DiamondType }>(records: T[]) {
  return records.filter(i => i.diamondTypeIndicator === DiamondType.Lab);
}

export function filterLabTypes<T extends { diamondTypeIndicator: DiamondType }>() {
  return (source: Observable<T[]>) => source.pipe(map(filterLabGrownRecords));
}

export const lgdMaxValues = {
  tablePercentage: 999.99,
  depthPercentage: 999.99,
  ratio: 99.999,
  lengthMM: 999.99,
  widthMM: 999.99,
  crownAngle: 999,
  pavAngle: 999
};
