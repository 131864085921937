import { getCurrencySymbol, formatCurrency } from '@angular/common';
import {
  DiamondSearchParameters,
  Filters,
  ResultOptions
} from '../../models';
import {
  RangeOptions,
  MultiSelectOption,
  Lookup,
  ColorType,
  SearchFilterOptions,
  PriceType,
  SearchParameters
} from '../models';
import { State } from '@progress/kendo-data-query';
import { DEFAULT_SEARCH_PARAMETERS_AGS } from '../config';
import { NaturalCartItem } from '../../models/natural-cart';
import { DiamondType } from '../../../shared/enums/diamond-type';

export function getRangeSliderOptions(ticksArray: number[]): RangeOptions {
  let floor = 0; let step1 = 0; let step2 = 0; let step3 = 0; let ceil = 0;
  const ticksPositions = [0, .25, .5, .75, 1];

  if (ticksArray) {
    floor = ticksArray[0]; // 0
    step1 = ticksArray[1]; // 10,000
    step2 = ticksArray[2]; // 25,000
    step3 = ticksArray[3]; // 100,000
    ceil = ticksArray[4]; // 999,999
  }

  const usdSymbol = getCurrencySymbol('USD', 'narrow', 'en-US');

  return {
    floor,
    ceil,
    isDigitOnly: true,
    draggableRange: true,
    animate: false,
    enforceRange: false,
    ticksArray,
    showTicksValues: true,

    customValueToPosition: (value: number, minVal: number, maxVal: number): number => {
      if (value <= step1) {
        return value / 40000;
      } else if (value > step1 && value <= step2) {
        return value / 50000;
      } else if (value >= step2 && value < step3) {
        return 0.180336 * Math.log(0.0006400000000000000 * value);
      } else if (value >= step3 && value < ceil) {
        return 0.108574 * Math.log(0.01000000000000000 * value);
      } else if (value === ceil) {
        return 1;
      }
    },
    customPositionToValue: (percent: number, minVal: number, maxVal: number): number => {
      if (percent <= ticksPositions[1]) { // 0 - 10,000
        return 40000 * percent;
      } else if (percent > ticksPositions[1] && percent <= ticksPositions[2]) { // 10,000 - 25,000
        return 50000 * percent;
      } else if (percent > ticksPositions[2] && percent <= ticksPositions[3]) {
        return Math.floor(1562.5 * Math.exp(5.5452 * percent));
      } else if (percent > ticksPositions[3] && percent <= ticksPositions[4]) {
        return Math.ceil(100 * Math.exp(9.2103 * percent));
      } else if (percent === ticksPositions[4]) {
        return 999999;
      }
    },
    translate: (value: number): string => {
      const formatted = formatCurrency(value, 'en-US', usdSymbol, 'USD', '1.0-0');
      const formattedCeil = formatCurrency(ceil, 'en-US', usdSymbol, 'USD', '1.0-0');
      return value >= ceil ? `${formattedCeil}+` : formatted;
    }
  };
}

export function getRangeStepsOptions(values: Lookup[], displayField: 'code' | 'description', skipValues: string[] = []): RangeOptions {
  return {
    showTicks: true,
    stepsArray: values ? values.filter(value => !skipValues.includes(value[displayField]))
      .map((value, index) => ({ value: index, legend: value[displayField] })) : [],
    draggableRange: true,
    animate: false,
    indexToCode: index => values[index].code,
    codeToIndex: code => values.findIndex(v => v.code === code),
    translate: (index: number): string => values ? values[index][displayField] : ''
  };
}

export function getAGSRangeStepsOptions(values: Lookup[], skipValues: string[] = []): RangeOptions {
  return {
    showTicks: true,
    stepsArray: values ? values.filter(value => !skipValues.includes(value.code))
      .map((value, index) => ({ value: index, legend: value.code })) : [],
    draggableRange: true,
    animate: false,
    indexToCode: index => values[index].code,
    codeToIndex: code => values.findIndex(v => v.code === code),
    translate: (index: number): string => values ? values[index].code + ' - ' + values[index].description : ''
  };
}

export function getAGSClarityStepsOptions(values: Lookup[], skipValues: string[] = []): RangeOptions {
  return {
    showTicks: true,
    stepsArray: values ? values.filter(value => !skipValues.includes(value.code))
      .map((value, index) => ({ value: index, legend: value.code })) : [],
    draggableRange: true,
    animate: false,
    indexToCode: index => {
      if (skipValues.length === 0) {
        return values[index].code;
      } else {
        const filteredValues = values.filter(value => !skipValues.includes(value.code));
        // Index goes out of range when we are removing values, in that case just return the last value
        return index > filteredValues.length - 1 ? filteredValues[filteredValues.length - 1].code : filteredValues[index].code;
      }
    },
    codeToIndex: code => {
      // If it is the last value, just return the last index
      // Otherwise wrong index is returned when values are removed
      if (code === values[values.length - 1].code) {
        return values.length - 1;
      }
      return skipValues.length === 0 ?
        values.findIndex(v => v.code === code) :
        values.filter(value => !skipValues.includes(value.code)).findIndex(v => v.code === code);
    },
    translate: (index: number): string => values.filter(value => !skipValues.includes(value.code)) ?
      values.filter(value => !skipValues.includes(value.code))[index].code : ''
  };
}

export function getCaratRangeOptions(values: number[]): RangeOptions {
  return {
    enforceStepsArray: false,
    enforceRange: false,

    showTicks: true,
    stepsArray: values ? values.map(value => ({ value, legend: getCaratLabel(value) })) : [],

    draggableRange: true,
    animate: false,
    isDigitOnly: false,
    hidePointerLabels: !!!values,

    translate: (value: number): string => {
      if (value > 10) {
        return '10+';
      }
      switch (value) {
        case 0.0025:
          return '.0025';
        case 10:
          return '10+';
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          return value + '.0';
        default:
          return '' + value;
      }
    }
  };
}

function getCaratLabel(value: number) {
  if (value === 0.0025) {
    return '.0025';
  } else if (value === 10) {
    return '10+';
  }
  return '';
}

export function getMultiselectOptionsCode(values: Lookup[], displayField: 'code' | 'description'): MultiSelectOption[] {
  return values ? values.map(value => ({ disabled: false, label: value[displayField], value: value.code })) : [];
}

export function getMultiselectOptionsCodeAndDescription(values: Lookup[],
  displayField: 'code' | 'description', valueField: 'code' | 'description'): MultiSelectOption[] {
  return values ? values.map(value => ({ disabled: false, label: value[displayField], value: value[valueField] })) : [];
}

export function getMultiselectOptionsCustomCodeAndLabel(values: any[], valueField: string, displayField: string): MultiSelectOption[] {
  return values ? values.map(value => ({ disabled: false, label: value[displayField], value: value[valueField] })) : [];
}

export function isRoundShapeSelected(shapes: string[]) {
  return shapes && shapes.includes('Round');
}

export function isAGSRoundShapeSelected(shapes: string[]) {
  return shapes && (
    shapes.includes('Round') ||
    shapes.includes('Cushion') ||
    shapes.includes('Emerald') ||
    shapes.includes('Octagon') ||
    shapes.includes('Oval') ||
    shapes.includes('Princess'));
}

export function isNotOnlyIdealCutGradeSelected(grades: string[]) {
  let notIdeal = false;
  grades?.forEach(grade => {
    if (grade !== 'ID') {
      notIdeal = true;
    }
  });

  return grades && notIdeal;
}

export function isAGSGradingLabSelected(gradingLabs: string[]) {
  return !!gradingLabs?.find(gradingLab => gradingLab === 'AGS' || gradingLab === 'All');
}

export function getDiamondSearchParameters(
  searchParameters: SearchParameters,
  searchFilterOptions: SearchFilterOptions,
  isAGSuser = false): DiamondSearchParameters {

  let filters: Filters = null;
  const resultOptions: ResultOptions = {
    resultLimit: 50,
    resultOffSet: 0,
    sortFields: [
      { sortField: 'priceTotal', sortDirection: 'asc' }
    ]
  };

  if (searchParameters.gradingReportNumber) {
    filters = {
      gradingReportNumber: searchParameters.gradingReportNumber,
      includeWithVisualOnly: searchParameters.includeWithVisualOnly
    };
  } else if (searchParameters.idexItemId) {
    filters = {
      idexItemId: searchParameters.idexItemId,
      includeWithVisualOnly: searchParameters.includeWithVisualOnly
    };
  } else {
    filters = {
      ...getBasicSearchFilters(searchParameters, searchFilterOptions, isAGSuser),
      ...getAdvanceSearchFilters(searchParameters, searchFilterOptions),
    };
  }

  return {
    filters,
    resultOptions
  };
}

export function getBasicSearchFilters(searchParameters: SearchParameters, searchFilterOptions: SearchFilterOptions, isAGSuser = false) {
  const filters: Filters = {
    weightMin: searchParameters.carat[0],
    weightMax: searchParameters.carat[1],
    colorType: searchParameters.colorType,
    clarities: getSelectedClarities(searchParameters.clarity, searchFilterOptions.clarities, isAGSuser),
    symmetries: getSelectedRange(searchParameters.symmetry, searchFilterOptions.symmetries),
    polishes: getSelectedRange(searchParameters.polish, searchFilterOptions.polishes),
    shapes: [...searchParameters.shapes, ...searchParameters.specialShapes],
    gradingLabs: sendAllValues(searchParameters.gradingLabs,
      filterNaturalRecords(searchFilterOptions.gradingLabs).map(g => g.code), 'All'),
    fluorescenceIntensities: filterAllValue(searchParameters.fluorescenceIntensity),
    fluorescenseColors: filterAllValue(searchParameters.fluorescenceColor),
    provenanceReports: filterAllValue(searchParameters.provenanceReports),
    includeWithVisualOnly: searchParameters.includeWithVisualOnly
  };

  if (isAGSuser) {
    filters.programs = searchParameters.programs;
  }

  if (isRoundShapeSelected(searchParameters.shapes) || isAGSuser && isAGSRoundShapeSelected(searchParameters.shapes)) {
    filters.cutGrades = getSelectedRange(searchParameters.cutGrade, isAGSuser && isAGSRoundShapeSelected(searchParameters.shapes) ?
      searchFilterOptions.cutGrades.filter(c => c.code !== 'UN') : searchFilterOptions.cutGrades);

    if (isAGSuser && isNotOnlyIdealCutGradeSelected(searchParameters.cutGrade)) {
      filters.lightPerformances = getSelectedRange(searchParameters.lightPerformances, searchFilterOptions.lightPerformances);
      filters.proportions = getSelectedRange(searchParameters.proportions, searchFilterOptions.proportionFactors);
    }
  }

  if (isAGSuser && isAGSGradingLabSelected(searchParameters.gradingLabs)) {
    filters.reportTypes = filterAllValue(searchParameters.reportTypes);
  }

  if (searchParameters.priceType === PriceType.TotalPrice) {
    filters.askingPriceTotalMin = searchParameters.price[0];
    filters.askingPriceTotalMax = searchParameters.price[1];
  } else {
    filters.askingPricePerCaratMin = searchParameters.price[0];
    filters.askingPricePerCaratMax = searchParameters.price[1];
  }

  if (searchParameters.colorType === ColorType.DiamondColor) {
    filters.colors = getSelectedRange(
      searchParameters.color,
      searchFilterOptions.colors.filter(c => c.colorType.code === ColorType.DiamondColor)
    );
  } else if (searchParameters.colorType === ColorType.NaturalFancyColor) {
    filters.naturalFancyColors = filterAllValue(searchParameters.naturalFancyColor);
    filters.fancyColorOvertones = filterAllValue(searchParameters.naturalFancyColorOvertone);
    filters.fancyColorIntensities = !searchParameters.naturalFancyColorIntensity || searchParameters.naturalFancyColorIntensity === 'All'
      ? null
      : [searchParameters.naturalFancyColorIntensity];
  }

  return filters;
}

export function getAdvanceSearchFilters(searchParameters: SearchParameters, searchFilterOptions: SearchFilterOptions): Filters {
  return {
    sourceCountryCodes: sendAllValues(searchParameters.sourceCountryCodes,
      filterNaturalRecords(searchFilterOptions.countries).map(m => m.countryCode)),
    totalDepthMin: searchParameters.totalDepthMin,
    totalDepthMax: searchParameters.totalDepthMax,
    tableWidthMin: searchParameters.tableWidthMin,
    tableWidthMax: searchParameters.tableWidthMax,
    measurementsLengthMin: searchParameters.measurementsLengthMin,
    measurementsLengthMax: searchParameters.measurementsLengthMax,
    measurementsWidthMin: searchParameters.measurementsWidthMin,
    measurementsWidthMax: searchParameters.measurementsWidthMax,
    measurementsHeightMin: searchParameters.measurementsHeightMin,
    measurementsHeightMax: searchParameters.measurementsHeightMax,
    culetSizes: filterAllValue(searchParameters.culetSizes),
    culetConditions: filterAllValue(searchParameters.culetConditions),
    girdleMin: searchParameters.girdleMin === 'All' ? null : searchParameters.girdleMin,
    girdleMax: searchParameters.girdleMax === 'All' ? null : searchParameters.girdleMax,
    milkies: filterAllValue(searchParameters.milkies),
    blackInclusions: filterAllValue(searchParameters.blackInclusions),
    eyeCleans: filterAllValue(searchParameters.eyeCleans),
    shades: filterAllValue(searchParameters.shades),
    includeWithVisualOnly: searchParameters.includeWithVisualOnly
  };
}

export function getSelectedRange([minValue, maxValue]: string[], allValues: Lookup[]): string[] {
  allValues = filterNaturalRecords(allValues);
  const startIndex = allValues.findIndex(c => c.code === minValue);
  const endIndex = allValues.findIndex(c => c.code === maxValue);

  if (endIndex - startIndex + 1 === allValues.length) {
    return null;
  } else {
    return allValues.slice(startIndex, endIndex + 1).map(c => c.code);
  }
}

export function getSelectedClarities([minValue, maxValue]: string[], allValues: Lookup[], isAGSuser = false): string[] {
  allValues = filterNaturalRecords(allValues);
  const startIndex = allValues.findIndex(c => c.code === minValue);
  const endIndex = allValues.findIndex(c => c.code === maxValue);

  if (isAGSuser) {
    return allValues.slice(startIndex, endIndex + 1).filter(c => c.code !== 'SI3').map(c => c.code);
  }

  if (endIndex - startIndex + 1 === allValues.length) {
    return null;
  } else {
    return allValues.slice(startIndex, endIndex + 1).map(c => c.code);
  }
}

export function sendAllValues(selectedValues: string[], allValues: string[], allOption = 'ALL'): string[] {
  if (selectedValues && selectedValues.length === 1) {
    const [selected] = selectedValues;

    // user selected all.. remove and send everything else.
    if (selected === allOption) {
      return allValues.filter(f => f !== allOption);
    }
  }

  // if user selects nothing, including all then send everything except all.
  // otherwise send their actual selections.
  return selectedValues.length === 0 ? allValues.filter(f => f !== allOption) : selectedValues;
}

export function filterAllValue(selectedValues: string[]): string[] {
  if (selectedValues && selectedValues.length === 1) {
    const [selected] = selectedValues;

    if (selected.toUpperCase() === 'ALL' || selected.toUpperCase() === 'A') {
      return null;
    }
  }

  return selectedValues;
}

export function getResultOptions(state: State): ResultOptions {
  return {
    resultLimit: state.take,
    resultOffSet: state.skip,
    sortFields: state.sort
      ? state.sort.map(sortOption => ({
        sortDirection: sortOption.dir,
        sortField: sortOption.field
      }))
      : null
  };
}

export function getSimilarSearchCriteria(
  cartItem: NaturalCartItem,
  defaultSearchFilterOptions: SearchParameters,
  searchFilterOptions: SearchFilterOptions,
  isAGSuser = false
): SearchParameters {
  return {
    ...defaultSearchFilterOptions,
    ...getShapeSimilarCriteria(cartItem, searchFilterOptions),
    ...getColorSimilarCriteria(cartItem, searchFilterOptions),
    ...getClaritySimilarCriteria(cartItem, searchFilterOptions, isAGSuser),
    ...getCutGradeSimilarCriteria(cartItem, searchFilterOptions, isAGSuser),
    ...getCaratSimilarCriteria(cartItem),
    ...getPriceTotalSimilarCriteria(cartItem),
    ...getGradingLabSimilarCriteria(cartItem),
    ...getCustomSearchSimilarCriteria(cartItem)
  };
}

export function filterNaturalRecords<T extends { diamondTypeIndicator: DiamondType }>(records: T[]) {
  return records.filter(i => i.diamondTypeIndicator === DiamondType.Natural);
}

function getShapeSimilarCriteria(cartItem: NaturalCartItem, { shapes }: SearchFilterOptions): Partial<SearchParameters> {
  const shape = shapes.find(s => s.shapeName === cartItem.shape);

  return shape.isSpecialShape
    ? { specialShapes: [shape.shapeName] }
    : { shapes: [shape.shapeName] };
}

function getColorSimilarCriteria(
  cartItem: NaturalCartItem,
  { colors, naturalFancyColorOvertones, naturalFancyColorIntensities }: SearchFilterOptions
): Partial<SearchParameters> {

  if (cartItem.color) {
    const diamondColors = colors.filter(c => c.colorType.code === ColorType.DiamondColor);
    const naturalFancyColors = colors.filter(c => c.colorType.code === ColorType.NaturalFancyColor);

    if (diamondColors.find(c => c.code === cartItem.color)) {
      const index = diamondColors.findIndex(c => c.code === cartItem.color);
      const minColor = index === 0 ? diamondColors[0].code : diamondColors[index - 1].code;
      const maxColor = index === diamondColors.length - 1 ? diamondColors[index].code : diamondColors[index + 1].code;

      return {
        colorType: ColorType.DiamondColor,
        color: [minColor, maxColor]
      };
    } else {
      const partialNaturalFancyCriteria: Partial<SearchParameters> = {
        colorType: ColorType.NaturalFancyColor
      };

      const colorParts = cartItem.color.split(' ');

      const naturalFancyColor = naturalFancyColors.find(c => colorParts.includes(c.description));
      if (naturalFancyColor) {
        partialNaturalFancyCriteria.naturalFancyColor = [naturalFancyColor.code];
      }

      const naturalFancyColorOvertone = naturalFancyColorOvertones.find(c => colorParts.includes(c.description));
      if (naturalFancyColorOvertone) {
        partialNaturalFancyCriteria.naturalFancyColorOvertone = [naturalFancyColorOvertone.code];
      }

      const naturalFancyColorIntensity = naturalFancyColorIntensities.find(c => colorParts.includes(c.description));
      if (naturalFancyColorIntensity) {
        partialNaturalFancyCriteria.naturalFancyColorIntensity = naturalFancyColorIntensity.code;
      }

      return partialNaturalFancyCriteria;
    }
  }
}

function getClaritySimilarCriteria(
  { clarity }: NaturalCartItem,
  { clarities }: SearchFilterOptions,
  isAGSUser = false): Partial<SearchParameters> {
  if (clarity) {
    if (isAGSUser) {
      clarities = clarities.filter(c => c.code !== 'SI3');
    }
    const index = clarities.findIndex(c => c.code === clarity);
    const minClarity = index === 0 ? clarities[0].code : clarities[index - 1].code;
    const maxClarity = index === clarities.length - 1 ? clarities[index].code : clarities[index + 1].code;
    return {
      clarity: [minClarity, maxClarity]
    };
  }
}

function getCutGradeSimilarCriteria(
  { cutGrade, shape }: NaturalCartItem,
  { cutGrades }: SearchFilterOptions,
  isAGSuser = false): Partial<SearchParameters> {
  const cutGradeCode = cutGrades.find(o => o.description === cutGrade);
  if (isAGSuser) {
    return agsUserCutGradeSimilarCriteria(cutGradeCode, cutGrades);
  } else if (cutGrade && isRoundShapeSelected([shape])) {
    return getCutGradeSearchScope(cutGradeCode.code, cutGradeCode.code);
  }
}

function getCutGradeSearchScope(cutGradeCodeUpperLimit: string, cutGradeCodeLowerLimit: string) {
  return {
    cutGrade: [cutGradeCodeUpperLimit, cutGradeCodeLowerLimit]
  };
}

function agsUserCutGradeSimilarCriteria(cutGrade: Lookup, allCutGrades: Lookup[]): Partial<SearchParameters> {
  if (!cutGrade) {
    return { cutGrade: DEFAULT_SEARCH_PARAMETERS_AGS.cutGrade };
  }

  const isCutGradeGrooup = cutGrade.scale.length > 2;
  if (isCutGradeGrooup) {
    return getCutGradeSearchScope(cutGrade.code, cutGrade.code);
  }
  const nextCutGrade = allCutGrades.find(x => x.priorityNumber === cutGrade.priorityNumber + 1)?.code ?? cutGrade.code;
  const prevCutGrade = allCutGrades.find(x => x.priorityNumber === cutGrade.priorityNumber - 1)?.code ?? cutGrade.code;

  return getCutGradeSearchScope(prevCutGrade, nextCutGrade);
}

function getCaratSimilarCriteria({ carat }: NaturalCartItem): Partial<SearchParameters> {
  if (carat) {
    const caratTopLimit = carat + carat / 100 * 10;
    return {
      carat: [carat, parseFloat(caratTopLimit.toFixed(4))]
    };
  }
}

function getPriceTotalSimilarCriteria({ price }: NaturalCartItem): Partial<SearchParameters> {
  let minPriceValue: any;
  let maxPriceValue: any;
  let priceRange: any;

  if (price <= 5000) {
    priceRange = price / 100 * 10;

    if (priceRange < 100) {
      priceRange = 100;
    }
  } else {
    priceRange = price / 100 * 3;
  }

  minPriceValue = price - priceRange;
  maxPriceValue = price + priceRange;

  if (minPriceValue < 0) {
    minPriceValue = 0;
  }

  return {
    price: [minPriceValue, maxPriceValue]
  };
}

function getGradingLabSimilarCriteria({ gradingLab }: NaturalCartItem): Partial<SearchParameters> {
  if (gradingLab) {
    return {
      gradingLabs: [gradingLab]
    };
  }
}

function getCustomSearchSimilarCriteria(cartItem: NaturalCartItem): Partial<SearchParameters> {
  return {
    programs: [cartItem.program],
    customSearch: !!cartItem.program
  };
}
